var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "订单商品",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-fixed-container"
  }, [_c('div', {
    staticClass: "list"
  }, _vm._l(_vm.list, function (orderGoods, index) {
    return _c('div', {
      key: index,
      staticClass: "goods"
    }, [_c('div', {
      staticClass: "image"
    }, [_c('van-image', {
      attrs: {
        "src": orderGoods.image
      }
    })], 1), _c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(orderGoods.name))]), _c('div', {
      staticClass: "size"
    }, [_vm._v("规格：1 * " + _vm._s(orderGoods.size))]), _c('div', {
      staticClass: "quantity"
    }, [_c('span', [_vm._v("数量：")]), orderGoods.large_volume ? _c('span', [_vm._v(" " + _vm._s(orderGoods.large_volume) + _vm._s(orderGoods.large_unit) + " ")]) : _vm._e(), orderGoods.least_volume ? _c('span', [_vm._v(" " + _vm._s(orderGoods.least_volume) + _vm._s(orderGoods.least_unit) + " ")]) : _vm._e()])])]);
  }), 0)]), _c('div', {
    staticClass: "fixed-bottom-btns"
  }, [_c('div', {
    staticClass: "bottom-btn"
  }, [_c('van-button', {
    attrs: {
      "square": "",
      "block": "",
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleCheck(1);
      }
    }
  }, [_vm._v("已收到商品")]), _c('van-button', {
    attrs: {
      "square": "",
      "block": "",
      "type": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.handleCheck(2);
      }
    }
  }, [_vm._v("未收到商品")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }