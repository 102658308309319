<template>
  <div>
    <my-nav-bar
      title="订单商品"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-fixed-container">
      <div class="list">
        <div v-for="(orderGoods, index) in list" :key="index" class="goods">
          <div class="image">
            <van-image :src="orderGoods.image" />
          </div>
          <div class="info">
            <div class="name">{{ orderGoods.name }}</div>
            <div class="size">规格：1 * {{ orderGoods.size }}</div>
            <div class="quantity">
              <span>数量：</span>
              <span v-if="orderGoods.large_volume">
                {{ orderGoods.large_volume }}{{ orderGoods.large_unit }}
              </span>
              <span v-if="orderGoods.least_volume">
                {{ orderGoods.least_volume }}{{ orderGoods.least_unit }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed-bottom-btns">
      <div class="bottom-btn">
        <van-button square block type="primary" @click="handleCheck(1)">已收到商品</van-button>
        <van-button square block type="warning" @click="handleCheck(2)">未收到商品</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getOrderNextDetail, checkOrderNext } from '@/api/order-next'
export default {
  components: { myNavBar },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.beginLoad()
    getOrderNextDetail(this.$route.query.order_next_id).then(res => {
      this.endLoad()
      this.list = res.data
    })
  },
  methods: {
    handleCheck(status) {
      let msg = ''
      if (status === 1) {
        msg = '确定已收到商品吗？'
      } else {
        msg = '确定未收到商品吗？'
      }
      this.confirm(msg, '提示').then(() => {
        this.beginLoad()
        const data = {
          status
        }
        const orderNextId = this.$route.query.order_next_id
        checkOrderNext(orderNextId, data).then(res => {
          this.success(res.msg)
          this.$eventBus.$emit('order-next-checked', orderNextId)
          this.$router.back()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 10px;
}
.goods {
  margin-bottom: 10px;
  background-color: #fff;
  padding: 10px;
  display: flex;
}
.goods:last-child {
  margin-bottom: 10px;
}
.image {
  width: 100px;
  height: 100px;
}
.image .van-image {
  width: 100px;
  height: 100px;
}
.info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.name {
  font-size: 15px;
}
.size {
  font-size: 13px;
  color: #989898;
}
.quantity {
  font-size: 13px;
  color: #989898;
}
.bottom-btn {
  display: flex;
  height: 100%;
}
.bottom-btn .van-button {
  height: 100%;
}
.fixed-bottom-btns {
  position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    background-color: #fff;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    z-index: 999;
}
</style>
